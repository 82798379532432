import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { CurrentSavedEventsContext } from "../../contexts/CurrentSavedEventsContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SaveBtn from "../SaveBtn/SaveBtn";
import "./EventsCard.css";

function EventsCard({ EventCard, onClickEventsBtn, movieOptions, loggedIn }) {
  const [isStartTime, setStartTime] = useState([]);
  const CurrentSaved = useContext(CurrentSavedEventsContext);
  const isLiked = CurrentSaved.some(
    (item) => +item.event_id === EventCard.event_id
  );

  useEffect(() => {
    splitDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickLike = () => {
    if (!isLiked) {
      onClickEventsBtn(EventCard, "save", EventCard.event_id);
    } else {
      onClickEventsBtn(
        EventCard,
        "delete",
        CurrentSaved.filter((item) => +item.event_id === EventCard.event_id)[0]
          .event_id
      );
    }
  };

  const handleClickDelete = () => {
    onClickEventsBtn(EventCard.event_id);
  };

  function splitDate() {
    const ArrTime = EventCard.start_time.split(/[-,T,:]/);
    setStartTime(ArrTime);
  }

  return (
    <div className="events-card">
      <NavLink
        to={"/aboutevent/" + EventCard.event_id}
        className="club-card__more"
      >
        <div className="events-card__header">
          <h1 className="events-card__title">{EventCard.name}</h1>
          <div className="events-card__container-data">
            <p className="events-card__date">{`${isStartTime[2]}.${isStartTime[1]}`}</p>
            <p className="events-card__time">{`${isStartTime[3]}:${isStartTime[4]}`}</p>
          </div>
        </div>
        <LazyLoadImage
          src={`https://api.clubbingowl.ru/images/${EventCard.images.at(-1)}`}
          className="events-card__img"
          alt="картинка события"
          effect="blur"
        />
      </NavLink>
      <div className="events-card__container">
        <p className="events-card__type">{`${EventCard.type} | ${EventCard.age_limit}+`}</p>
        <p className="events-card__bar-name">{EventCard.place}</p>
        <div className="club-card__btns">
          {movieOptions === "all" ? (
            loggedIn ? (
              <SaveBtn isLiked={isLiked} onClick={handleClickLike} />
            ) : (
              " "
            )
          ) : (
            <button
              className="club-card__like_remove"
              onClick={handleClickDelete}
            ></button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventsCard;
