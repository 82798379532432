import { BASE_URL } from "./auth";

class MainApi {
  constructor(options) {
    this._baseUrl = options.baseUrl;
    this._headers = options.headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  }

  getUserInfo(token, userId) {
    return fetch(`${BASE_URL}/users/${userId}`, {
      method: "GET",
      headers: {
        "X-Auth-Token": token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  handleAvatar(form) {
    return fetch(`${BASE_URL}/users/avatar`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
      },
      body: form,
    }).then((res) => this._checkResponse(res));
  }

  handleUserInfo(fullname, date_of_birth, city, telegram, phone_number) {
    return fetch(`${BASE_URL}/users/${localStorage.getItem("userId")}`, {
      method: "PUT",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fullname,
        date_of_birth,
        city,
        telegram,
        phone_number,
      }),
    }).then((res) => this._checkResponse(res));
  }

  getAllPlaces() {
    return fetch(`${BASE_URL}/places`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getShowPlace(url_id) {
    return fetch(`${BASE_URL}/places/${url_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getSavedPlaces(token, userId) {
    return fetch(
      `${BASE_URL}/places/favourites/?user_id=${userId}`, {
        method: "GET",
        headers: {
          "X-Auth-Token": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((res) => this._checkResponse(res));
  }

  addSavedPlace(place_id) {
    return fetch(`${BASE_URL}/places/favourites/${place_id}?user_id=${localStorage.getItem("userId")}`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  deleteSavedPlace(place_id) {
    return fetch(`${BASE_URL}/places/favourites/${place_id}?user_id=${localStorage.getItem("userId")}`, {
      method: "DELETE",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getAllEvents() {
    return fetch(`${BASE_URL}/events`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getShowEvent(event_id) {
    return fetch(`${BASE_URL}/events/${event_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getSavedEvents(token, userId) {
    return fetch(
      `${BASE_URL}/events/favourites/?user_id=${userId}`, {
        method: "GET",
        headers: {
          "X-Auth-Token": token,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    ).then((res) => this._checkResponse(res));
  }

  addSavedEvent(event_id) {
    return fetch(`${BASE_URL}/events/favourites/${event_id}?user_id=${localStorage.getItem("userId")}`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  deleteSavedEvent(event_id) {
    return fetch(`${BASE_URL}/events/favourites/${event_id}?user_id=${localStorage.getItem("userId")}`, {
      method: "DELETE",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

}

const mainApi = new MainApi({
  baseUrl: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default mainApi;
