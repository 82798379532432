import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { CurrentSavedPlacesContext } from "../../contexts/CurrentSavedPlacesContext";
import { CurrentSavedEventsContext } from "../../contexts/CurrentSavedEventsContext";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import SavedPage from "../../pages/SavedPage";
import Map from "../Map/Map";
import EventBarPage from "../../pages/EventBarPage";
import ProfilePage from "../../pages/ProfilePage";
import Register from "../Register/Register";
import Login from "../Login/Login";
import CurrentClubCard from "../CurrentClubCard/CurrentClubCard";
import CurrentEventCard from "../CurrentEventCard/CurrentEventCard";
import NotFound from "../NotFound/NotFound";
import Popup from "../Popup/Popup";
import * as auth from "../../utils/auth";
import mainApi from "../../utils/MainApi";
import ConfirmEmail from "../ConfirmEmail/ConfirmEmail";
import YandexLogin from "../YandexLogin/YandexLogin";
import AdminPage from "../../pages/AdminPage/AdminPage";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isRegisterError, setRegisterError] = useState(false);
  const [isLoginError, setLoginError] = useState(false);
  const [isProfileError, setProfileError] = useState(false);
  const [isErrorText, setErrorText] = useState("");
  const [isAboutUsPopupOpen, setAboutUsPopupOpen] = useState(false);
  const [isSavedPlaces, setSavedPlaces] = useState([]);
  const [isSavedEvents, setSavedEvents] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        closeAllPopups();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      checkToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const token = localStorage.getItem("jwt");
      const userId = localStorage.getItem("userId");
      Promise.all([
        mainApi.getUserInfo(token, userId),
        mainApi.getSavedPlaces(token, userId),
        mainApi.getSavedEvents(token, userId),
      ])
        .then((data) => {
          const [userData, savedPlaces, savedEvents] = data;
          setCurrentUser(userData);
          setSavedPlaces(savedPlaces.reverse());
          setSavedEvents(savedEvents.reverse());
        })
        .catch((err) => console.log(err));
    }
  }, [loggedIn]);

  function handleAboutUsPopupOpen() {
    setAboutUsPopupOpen(true);
  }

  function closeAllPopups() {
    setAboutUsPopupOpen(false);
  }

  function checkToken() {
    const token = localStorage.getItem("jwt");
    const userId = localStorage.getItem("userId");
    if (token) {
      auth
        .checkToken(token, userId)
        .then((data) => {
          if (data) {
            setLoggedIn(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const handleLogin = (password, email) => {
    auth
      .authorize(password, email)
      .then((data) => {
        if (data[0]) {
          localStorage.setItem("jwt", data[0]);
          (async () => {
            const result = await data[1];
            return localStorage.setItem("userId", result.user_id);
          })();
          navigate("/");
          setTimeout(() => {
            setLoggedIn(true);
          }, 500);
        }
      })
      .catch((err) => {
        setLoginError(true);
        if (err === "Ошибка 401")
          setErrorText(
            "Возможно вы не зарегистрированы или ввели неверные данные"
          );
        if (err === "Ошибка 429")
          setErrorText(
            "Превышено максимально количество авторизации, подождите пару минут и попробуйте снова"
          );
        console.error(err);
      });
  };

  const handleLoginYandex = (token) => {
    auth
      .authorizeYandex(token)
      .then((data) => {
        if (data[0]) {
          localStorage.setItem("jwt", data[0]);
          (async () => {
            const result = await data[1];
            return localStorage.setItem("userId", result.user_id);
          })();
          navigate("/");
          setTimeout(() => {
            setLoggedIn(true);
            checkToken();
          }, 500);
        }
      })
      .catch((err) => {
        setLoginError(true);
        if (err === "Ошибка 401")
          setErrorText(
            "Возможно вы не зарегистрированы или ввели неверные данные"
          );
        if (err === "Ошибка 429")
          setErrorText(
            "Превышено максимально количество авторизации, подождите пару минут и попробуйте снова"
          );
        console.error(err);
      });
  };

  const handleRegister = (
    email,
    fullname,
    city,
    phone_number,
    date_of_birth,
    password
  ) => {
    auth
      .register(email, fullname, city, phone_number, date_of_birth, password)
      .then((data) => {
        if (data) {
          setRegisterError(true);
          setErrorText(
            "Для завершения регистрации подтвердите аккаунт на вашей почте"
          );
        }
      })
      .catch((err) => {
        setRegisterError(true);
        if (err === "Ошибка 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка 409")
          setErrorText("Пользователь с таким e-mail уже существует");
        if (err === "Ошибка 500") setErrorText("Ошибка по умолчанию");
        console.error(err);
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    setLoggedIn(false);
    setCurrentUser({});
    setSavedPlaces([]);
    setSavedEvents([]);
    setErrorText("");
  };

  const handleUpdateUserInfo = (newUserData) => {
    const { fullname, date_of_birth, city, telegram, phone_number } = newUserData;
    mainApi
      .handleUserInfo(fullname, date_of_birth, city, telegram, phone_number)
      .then(() => {
        checkToken();
      })
      .catch((err) => {
        setProfileError(true);
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  const handleUpdateAvatar = (newAvatar) => {
    mainApi
      .handleAvatar(newAvatar)
      .then(() => {})
      .catch((err) => {
        setProfileError(true);
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  function onClickDeletePlace(place_id) {
    mainApi
      .deleteSavedPlace(place_id)
      .then((res) => {
        if (res) {
          setSavedPlaces((cards) =>
            cards.filter((item) => item.place_id !== place_id)
          );
        }
      })
      .catch((err) => console.log(err));
  }

  function onClickSavePlace(bar, clickBtn, place_id) {
    if (loggedIn) {
      if (clickBtn === "delete") {
        onClickDeletePlace(place_id);
        return;
      }
      mainApi
        .addSavedPlace(place_id)
        .then((res) => {
          if (res) {
            setSavedPlaces((prev) => [...prev, bar]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Вы не авторизованы");
    }
  }

  function onClickDeleteEvent(event_id) {
    mainApi
      .deleteSavedEvent(event_id)
      .then((res) => {
        if (res) {
          setSavedEvents((cards) =>
            cards.filter((item) => item.event_id !== event_id)
          );
        }
      })
      .catch((err) => console.log(err));
  }

  function onClickSaveEvent(event, clickBtn, event_id) {
    if (loggedIn) {
      if (clickBtn === "delete") {
        onClickDeleteEvent(event_id);
        return;
      }
      mainApi
        .addSavedEvent(event_id)
        .then((res) => {
          if (res) {
            setSavedEvents((prev) => [...prev, event]);
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Вы не авторизованы");
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <CurrentSavedPlacesContext.Provider value={isSavedPlaces}>
        <CurrentSavedEventsContext.Provider value={isSavedEvents}>
          <div className="app">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <>
                    <Popup
                      isOpen={isAboutUsPopupOpen}
                      onClose={closeAllPopups}
                    />
                    <Main
                      onClickSavePlace={onClickSavePlace}
                      loggedIn={loggedIn}
                    />
                    <Footer handleAboutUsPopupOpen={handleAboutUsPopupOpen} />
                    <Navigation />
                  </>
                }
              />

              <Route
                path="/event-bar"
                element={
                  <>
                    <Popup
                      isOpen={isAboutUsPopupOpen}
                      onClose={closeAllPopups}
                    />
                    <EventBarPage
                      loggedIn={loggedIn}
                      handleAboutUsPopupOpen={handleAboutUsPopupOpen}
                      onClickSaveEvent={onClickSaveEvent}
                    />
                  </>
                }
              />

              <Route
                path="/map"
                element={
                  <>
                    <Map />
                    <Navigation />
                  </>
                }
              />

              <Route
                path="/saved"
                element={
                  <>
                    <Popup
                      isOpen={isAboutUsPopupOpen}
                      onClose={closeAllPopups}
                    />
                    <SavedPage
                      loggedIn={loggedIn}
                      handleAboutUsPopupOpen={handleAboutUsPopupOpen}
                      onClickDeletePlace={onClickDeletePlace}
                      onClickDeleteEvent={onClickDeleteEvent}
                    />
                  </>
                }
              />

              <Route
                path="/profile"
                element={
                  <ProfilePage
                    loggedIn={loggedIn}
                    onLogout={handleLogout}
                    handleUpdateUserInfo={handleUpdateUserInfo}
                    handleUpdateAvatar={handleUpdateAvatar}
                    currentUser={currentUser}
                    isProfileError={isProfileError}
                    isErrorText={isErrorText}
                  />
                }
              />

              <Route path="/admin" element={<AdminPage />} />

              <Route
                path="/aboutplace/:url_id"
                element={
                  <>
                    <CurrentClubCard
                      loggedIn={loggedIn}
                      onClickPlaceBtn={onClickSavePlace}
                      onClickEventsBtn={onClickSaveEvent}
                    />
                    <Navigation />
                  </>
                }
              />

              <Route
                path="/aboutevent/:event_id"
                element={
                  <>
                    <CurrentEventCard
                      onClickEventsBtn={onClickSaveEvent}
                      loggedIn={loggedIn}
                    />
                    <Navigation />
                  </>
                }
              />

              <Route
                path="/yandex_auth/token"
                element={
                  <>
                    <YandexLogin handleLoginYandex={handleLoginYandex} />
                  </>
                }
              />

              <Route
                path="/users/confirm_email/:token"
                element={<ConfirmEmail />}
              />

              <Route
                path="/signup"
                element={
                  loggedIn ? (
                    <Navigate to="/" />
                  ) : (
                    <>
                      <Register
                        handleRegister={handleRegister}
                        isRegisterError={isRegisterError}
                        isErrorText={isErrorText}
                      />
                      <Navigation />
                    </>
                  )
                }
              />

              <Route
                path="/signin"
                element={
                  loggedIn ? (
                    <Navigate to="/" />
                  ) : (
                    <>
                      <Login
                        handleLogin={handleLogin}
                        isLoginError={isLoginError}
                        isErrorText={isErrorText}
                      />
                      <Navigation />
                    </>
                  )
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </CurrentSavedEventsContext.Provider>
      </CurrentSavedPlacesContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default App;
