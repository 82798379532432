import React from "react";
import "./Map.css";

function Map() {
  return (
    <div className="map">
      <iframe
        title="yandex-map"
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Af4cc7eae69f19812b596c0645aa8feb4869dc47ef716ecd7cc83a24af8bd7dd7&amp;source=constructor"
        className="map__yandex"
        frameBorder="0"
      ></iframe>
    </div>
  );
}
export default Map;
