import React from "react";
import Navigation from "../components/Navigation/Navigation";
import Profile from "../components/Profile/Profile";
import ProfileUnauthorized from "../components/ProfileUnauthorized/ProfileUnauthorized";

const ProfilePage = (props) => (
  <React.Fragment>
    {props.loggedIn ? (
      <Profile
        onLogout={props.onLogout}
        handleUpdateUserInfo={props.handleUpdateUserInfo}
        handleUpdateAvatar={props.handleUpdateAvatar}
        currentUser={props.currentUser}
        isProfileError={props.isProfileError}
        isErrorText={props.isErrorText}
      />
    ) : (
      <ProfileUnauthorized text={"Тусовки твоего города"} />
    )}
    <Navigation />
  </React.Fragment>
);

export default ProfilePage;
