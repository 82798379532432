import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CurrentSavedPlacesContext } from "../../contexts/CurrentSavedPlacesContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SaveBtn from "../SaveBtn/SaveBtn";
import "./ClubCard.css";

function ClubCard({ bar, onClickPlaceBtn, movieOptions, loggedIn }) {
  const CurrentSaved = useContext(CurrentSavedPlacesContext);
  const isLiked = CurrentSaved.some((item) => +item.place_id === bar.place_id);

  const handleClickLike = () => {
    if (!isLiked) {
      onClickPlaceBtn(bar, "save", bar.place_id);
    } else {
      onClickPlaceBtn(
        bar,
        "delete",
        CurrentSaved.filter((item) => +item.place_id === bar.place_id)[0]
          .place_id
      );
    }
  };

  const handleClickDelete = () => {
    onClickPlaceBtn(bar.place_id);
  };

  return (
    <div className="club-card">
      <Link to={"/aboutplace/" + bar.url_id} className="club-card__more">
        <div className="club-card__header">
          <h2 className="club-card__title">{bar.name}</h2>
          <div className="club-card__age">
            <p className="club-card__age-number">{`${bar.age_limit}+`}</p>
          </div>
        </div>
        <LazyLoadImage src={`https://api.clubbingowl.ru/images/${bar.images[0]}`}
        className="club-card__img"
        alt="картинка бара"
        effect="blur"
      />
      </Link>
      <div className="club-card__information">
        <p className="club-card__text">{bar.type}</p>
        <p className="club-card__text">{bar.address}</p>
        <div className="club-card__btns">
          {movieOptions === "all" ? (
            loggedIn ? (
              <SaveBtn isLiked={isLiked} onClick={handleClickLike} />
            ) : (
              " "
            )
          ) : (
            <button
              className="club-card__like_remove"
              onClick={handleClickDelete}
            ></button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClubCard;
