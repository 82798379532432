import React, { useState, useEffect } from "react";
import "./EventBar.css";
import EventsList from "../EventsList/EventsList";
import SearchForm from "../SearchForm/SearchForm";
import mainApi from "../../utils/MainApi";
import Preloader from "../Preloader/Preloader";

function EventBar({ onClickSaveEvent, loggedIn }) {
  const [isEvents, setEvents] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isRender, setRender] = useState(false);

  useEffect(() => {
    const dataEventBar = JSON.parse(sessionStorage.getItem("dataEventBar"));
    if (dataEventBar === null) {
      getDataEvents();
      setTimeout(() => {
        sessionStorage.removeItem("dataEventBar");
      }, 3600000);
    } else {
      setEvents(dataEventBar);
      setLoading(true);
    }
    if (isLoading) {
      setRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function getDataEvents() {
    mainApi.getAllEvents().then((res) => {
      sessionStorage.setItem("dataEventBar", JSON.stringify(res));
      setEvents(res);
      setLoading(true);
    });
  }

  function searchEvents() {
    const dataEventBar = JSON.parse(sessionStorage.getItem("dataEventBar"));
    const searchStringText = localStorage
      .getItem("searchEventsStringText")
      .toLowerCase();
    const listFoundEvents = dataEventBar.filter(
      (events) => events.name.toLowerCase().indexOf(searchStringText) >= 0
    );
    setEvents(listFoundEvents);
    return listFoundEvents;
  }

  function handleSearchEvents(searchStringText) {
    localStorage.setItem("searchEventsStringText", searchStringText);
    searchEvents();
  }

  function handleClickShowAllEvents() {
    const dataEventBar = JSON.parse(sessionStorage.getItem("dataEventBar"));
    setEvents(dataEventBar);
  }

  return (
    <div className="event-bar">
      <SearchForm
        onSubmit={handleSearchEvents}
        showAll={handleClickShowAllEvents}
        placeholderText={"Поиск события"}
      />
      {isRender ? (
        <EventsList
          arrEvents={isEvents}
          movieOptions={"all"}
          onClickEventsBtn={onClickSaveEvent}
          loggedIn={loggedIn}
        />
      ) : (
        <Preloader />
      )}
    </div>
  );
}
export default EventBar;
