import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "./CurrentClubCard.css";
import CarouselCurrentClub from "../CarouselCurrentClub/CarouselCurrentClub";
import mainApi from "../../utils/MainApi";
import EventsList from "../EventsList/EventsList";
import Preloader from "../Preloader/Preloader";
import YandexShare from "../YandexShare/YandexShare";
import SaveBtn from "../SaveBtn/SaveBtn";
import { CurrentSavedPlacesContext } from "../../contexts/CurrentSavedPlacesContext";

function CurrentClubCard({ onClickPlaceBtn, onClickEventsBtn, loggedIn }) {
  let { url_id } = useParams();
  const [isBar, setIsBar] = useState([]);
  const [isEvent, setEvent] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isRender, setRender] = useState(false);
  const textAddress = document.querySelector("#club-address-text");
  const textPhone = document.querySelector("#club-phone-text");
  const CurrentSaved = useContext(CurrentSavedPlacesContext);
  const isLiked = CurrentSaved.some(
    (item) => +item.place_id === isBar.place_id
  );

  useEffect(() => {
    getDataBar();
    getDataEvents();
    if (isLoading) {
      setRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleClickLike = () => {
    if (!isLiked) {
      onClickPlaceBtn(isBar, "save", isBar.place_id);
    } else {
      onClickPlaceBtn(
        isBar,
        "delete",
        CurrentSaved.filter((item) => +item.place_id === isBar.place_id)[0]
          .place_id
      );
    }
  };

  function getDataBar() {
    mainApi
      .getShowPlace(url_id)
      .then((res) => {
        setIsBar(res);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getDataEvents() {
    mainApi.getAllEvents().then((res) => {
      const testList = res.filter((eve) => eve.place_id === isBar.place_id);
      setEvent(testList);
    });
  }

  function copyText(e, text) {
    window.navigator.clipboard.writeText(text.innerHTML);
    e.target.insertAdjacentHTML(
      "beforeend",
      `<p style="color: white; margin: 0; position:absolute; top:0; right: -115px;">Текст скопирован</p>`
    );
    setTimeout(() => e.target.lastChild.remove(), 1000);
  }

  const getFormatedText = (text) => {
    return text.split("\n").map((str, i) => (
      <p className="current-clubcard__text" key={`p_${i}`}>
        {str}
      </p>
    ));
  };

  return (
    <div className="current-clubcard">
      {isRender ? (
        <>
          <div className="current-clubcard__header">
            <h1 className="current-clubcard__title">{isBar.name}</h1>
            <div className="current-clubcard__btns">
              {loggedIn ? (
                <SaveBtn isLiked={isLiked} onClick={handleClickLike} />
              ) : (
                ""
              )}
              <YandexShare />
            </div>
          </div>
          <div className="current-clubcard__scrollimg-box">
            <p className="current-clubcard__age-limit">{isBar.age_limit}+</p>
            <CarouselCurrentClub allImg={isBar.images} />
          </div>
          {getFormatedText(isBar.description)}
          {isBar.website !== null ? (
            <a
              href={isBar.website}
              target="_blank"
              className="current-clubcard__link"
              rel="noreferrer"
            >
              Перейти на сайт клуба
            </a>
          ) : (
            " "
          )}
          <div className="current-clubcard__container-copy">
            <p id="club-address-text" className="current-clubcard__copy-text">
              {isBar.address}
            </p>
            <button
              className="current-clubcard__btn-copy"
              onClick={(e) => copyText(e, textAddress)}
            >
              Скопировать адрес
            </button>
          </div>
          {isBar.phone !== null ? (
            <div className="current-clubcard__container-copy">
              <p id="club-phone-text" className="current-clubcard__copy-text">
                {isBar.phone}
              </p>
              <button
                className="current-clubcard__btn-copy"
                onClick={(e) => copyText(e, textPhone)}
              >
                Скопировать номер
              </button>
            </div>
          ) : (
            " "
          )}
          {/* <a
            className="current-clubcard__btn"
            href="#current-clubcard__map-box"
            target="_self"
          >
            Как проехать?
          </a> */}
          <div className="current-clubcard__events-box">
            <p className="current-clubcard__events-title">Мероприятия</p>
            {isEvent.length === 0 ? (
              <p className="current-clubcard__text-no-events">
                В ближайшее время нет мероприятий
              </p>
            ) : (
              <EventsList
                arrEvents={isEvent}
                onClickEventsBtn={onClickEventsBtn}
                movieOptions="all"
                loggedIn={loggedIn}
              />
            )}
          </div>
          <div
            id="current-clubcard__map-box"
            className="current-clubcard__map-box"
          >
            {/* <p className="current-clubcard__map-title">Как проехать?</p>
            <iframe
              title="bar_pozition"
              className="current-clubcard__map"
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A55d1bd2a64b3fbee392ee9bfc3053f872112ce4f11ab1df2e2f1ac9b6e9ead09&amp;source=constructor"
              frameBorder="0"
            ></iframe> */}
          </div>
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
}

export default CurrentClubCard;
