import React from "react";
import Navigation from "../components/Navigation/Navigation";
import Saved from "../components/Saved/Saved";
import ProfileUnauthorized from "../components/ProfileUnauthorized/ProfileUnauthorized";
import Footer from "../components/Footer/Footer";

const SavedPage = (props) => (
  <React.Fragment>
    {props.loggedIn ? (
      <>
        <Saved
          onClickDeletePlace={props.onClickDeletePlace}
          onClickDeleteEvent={props.onClickDeleteEvent}
        />
        <Footer handleAboutUsPopupOpen={props.handleAboutUsPopupOpen} />
      </>
    ) : (
      <ProfileUnauthorized
        text={"Для использования этого контента требуется авторизация"}
      />
    )}
    <Navigation />
  </React.Fragment>
);

export default SavedPage;
