import React from "react";
import "./ProfileUnauthorized.css";
import { NavLink } from "react-router-dom";
import Logo from "../Logo/Logo";

function ProfileUnauthorized(props) {
  return (
    <div className="profile-unauthorized">
      <div className="profile-unauthorized__content">
        <Logo />
        <p className="profile-unauthorized__text">{props.text}</p>
      </div>
      <div className="profile-unauthorized__links">
        <NavLink to="/signup" className="profile-unauthorized__link">
          Зарегистрироваться
        </NavLink>
        <NavLink
          to="/signin"
          className="profile-unauthorized__link profile-unauthorized__link_login"
        >
          Войти
        </NavLink>
        <div className="profile-unauthorized__container-login-yandex">
          <p className="profile-unauthorized__line-login-yandex">или</p>
          <a
            type="button"
            className="profile-unauthorized__login-yandex"
            target="_self"
            href="https://oauth.yandex.ru/authorize?response_type=token&client_id=835867d62d754026890b28d9a8af5a16"
          >
            Войти с Яндекс ID
          </a>
        </div>
      </div>
    </div>
  );
}
export default ProfileUnauthorized;
