import React, { useState, useEffect } from "react";
import useValidation from "../../hooks/useValidation";
import { NavLink } from "react-router-dom";
import "./Profile.css";

function Profile(props) {
  const { values, setValues, handleChange, errors, isValid, setIsValid } =
    useValidation();
  const [avatarProfile, setAvatarProfile] = useState(null);
  const [loadImage, setLoadImage] = useState(null);

  useEffect(() => {
    setValues(props.currentUser);
    setIsValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser, setValues, setIsValid]);

  useEffect(() => {
    const objectUrl = loadImage && URL.createObjectURL(loadImage);
    setAvatarProfile(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [loadImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleUpdateUserInfo(values);
  };

  const handleSubmitAvatar = (e) => {
    setLoadImage(e.target.files[0]);
    const formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    props.handleUpdateAvatar(formdata);
  };

  return (
    <div className="profile">
      <form
        className="profile__container"
        id="profile_form"
        onSubmit={handleSubmit}
      >
        <div className="profile__avatar-box">
          <img
            className="profile__avatar"
            src={
              avatarProfile ||
              `https://api.clubbingowl.ru/images/${props.currentUser.avatar_url}`
            }
            alt=" "
          />
          <label
            className="profile__avatar-edit-label"
            htmlFor="avatar-edit-button"
          ></label>
          <input
            type="file"
            className="profile__avatar-edit-button"
            id="avatar-edit-button"
            name="image"
            accept=".png, .jpg, .jpeg"
            value={values.avatar || ""}
            onChange={handleSubmitAvatar}
          ></input>
        </div>

        <div className="profile__input">
          <label className="profile__input-label">Имя</label>
          <input
            type="text"
            className="profile__input-item"
            id="fullname"
            name="fullname"
            maxLength="30"
            minLength="2"
            placeholder="Имя"
            required
            pattern="[А-Я][а-я]+\s[А-Я][а-я]+"
            value={values.fullname || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.fullname}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Дата рождения</label>
          <input
            type="date"
            className="profile__input-item"
            id="date_of_birth"
            name="date_of_birth"
            placeholder="Дата рождения"
            value={values.date_of_birth || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.date_of_birth}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Город</label>
          <input
            type="text"
            className="profile__input-item"
            id="city"
            name="city"
            maxLength="30"
            placeholder="Город"
            pattern="[A-Za-zА-ЯЁа-яё -]+"
            value={values.city || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.city}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">E-mail</label>
          <input
            type="email"
            className="profile__input-item"
            id="email"
            name="email"
            placeholder="Почта"
            required
            pattern="^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$"
            value={values.email || ""}
            onChange={handleChange}
            disabled
          />
          <span className="profile__input-error">{errors.email}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Номер телефона</label>
          <input
            type="tel"
            className="profile__input-item"
            id="phone_number"
            name="phone_number"
            minLength={12}
            maxLength={12}
            placeholder="+7**********"
            pattern="\+?[0-9\s\-\(\)]+"
            value={values.phone_number || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.phone_number}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Телеграм</label>
          <input
            type="text"
            className="profile__input-item"
            id="telegram"
            name="telegram"
            placeholder="@clubbing-owl"
            pattern="(?:@|(?:(?:(?:https?://)?t(?:elegram)?)\.me\/))(\w{4,})$"
            value={values.telegram || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.telegram}</span>
        </div>
        <fieldset className="profile__submit-group">
          {props.isProfileError && (
            <span className="profile__submit-group-error">
              {props.isErrorText}
            </span>
          )}
          <button
            className={
              isValid &&
              (values.fullname !== props.currentUser.fullname ||
                values.date_of_birth !== props.currentUser.date_of_birth ||
                values.city !== props.currentUser.city ||
                values.phone_number !== props.currentUser.phone_number ||
                values.telegram !== props.currentUser.telegram)
                ? "profile__button"
                : "profile__button profile__button-inactive"
            }
            disabled={
              (values.fullname === props.currentUser.fullname &&
                values.date_of_birth === props.currentUser.date_of_birth &&
                values.city === props.currentUser.city &&
                values.phone_number === props.currentUser.phone_number &&
                values.telegram === props.currentUser.telegram) ||
              !isValid
            }
            type="submit"
            id="profile__button"
          >
            Редактировать
          </button>
          <NavLink to="/" className="profile__link" onClick={props.onLogout}>
            Выйти из аккаунта
          </NavLink>
        </fieldset>
      </form>
    </div>
  );
}
export default Profile;
