import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Form from "../Form/Form";
import Input from "../Input/Input";
import Logo from "../Logo/Logo";
import useValidation from "../../hooks/useValidation";
import "./Register.css";

function Register(props) {
  const { values, handleChange, errors, isValid, resetForm } = useValidation();

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleRegister(
      values.email,
      values.fullname,
      values.city,
      values.phone_number,
      values.date_of_birth,
      values.password
    );
  };

  return (
    <div className="register">
      <NavLink to="/" className="register__auth-logo">
        <Logo />
      </NavLink>

      <Form
        name="form-register"
        title="Добро пожаловать!"
        FormBtnRegister
        isDisabled={!isValid}
        onSubmit={handleSubmit}
        textBtn="Зарегистрироваться"
        textSpan="Уже зарегистрированы?"
        Link="/signin"
        textLink="Войти"
        isRegisterError={props.isRegisterError}
        isErrorText={props.isErrorText}
      >
        <Input
          type="email"
          id="email"
          name="email"
          maxLength="40"
          minLength="2"
          pattern="^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$"
          placeholder="E-mail"
          required
          errorId="email-error"
          onChange={handleChange}
          value={values.email || ""}
          errorText={errors.email}
        >
          E-mail <span className="register__required">*</span>
        </Input>

        <Input
          type="text"
          id="fullname"
          name="fullname"
          maxLength="30"
          minLength="2"
          placeholder="Имя"
          pattern="[А-Я][а-я]+"
          required
          errorId="fullname-error"
          onChange={handleChange}
          value={values.fullname || ""}
          errorText={errors.fullname}
        >
          Имя <span className="register__required">*</span>
        </Input>

        <Input
          type="text"
          id="city"
          name="city"
          maxLength="30"
          minLength="2"
          placeholder="Город"
          pattern="[A-Za-zА-ЯЁа-яё -]+"
          required
          errorId="city-error"
          onChange={handleChange}
          value={values.city || ""}
          errorText={errors.city}
        >
          Город <span className="register__required">*</span>
        </Input>

        <Input
          type="tel"
          id="phone_number"
          name="phone_number"
          minLength={12}
          maxLength={12}
          placeholder="+7**********"
          pattern="\+?[0-9\s\-\(\)]+"
          errorId="phone_number-error"
          onChange={handleChange}
          value={values.phone_number || ""}
          errorText={errors.phone_number}
        >
          Номер телефона
        </Input>

        <Input
          type="date"
          id="date_of_birth"
          name="date_of_birth"
          placeholder="Дата рождения"
          required
          errorId="date_of_birth-error"
          onChange={handleChange}
          value={values.date_of_birth || ""}
          errorText={errors.date_of_birth}
        >
          Дата рождения <span className="register__required">*</span>
        </Input>

        <Input
          type="password"
          id="password"
          name="password"
          placeholder="Пароль"
          required
          errorId="password-error"
          onChange={handleChange}
          value={values.password || ""}
          errorText={errors.password}
        >
          Пароль <span className="register__required">*</span>
        </Input>
      </Form>
    </div>
  );
}

export default Register;
