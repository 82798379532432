import React from "react";
import "./SavedToggleBtn.css";

function SavedToggleBtn(props) {
  return (
    <div className="saved__btn-container">
      <button
        className={
          props.isToggleBtn
            ? "saved__btn-place saved__btn-place_active"
            : "saved__btn-place"
        }
        onClick={() => {
          props.setToggleBtn(true);
        }}
      >
        {props.textFirstBtn}
      </button>
      <button
        className={
          props.isToggleBtn
            ? "saved__btn-event"
            : "saved__btn-event saved__btn-event_active"
        }
        onClick={() => {
          props.setToggleBtn(false);
        }}
      >
        {props.textTwoBtn}
      </button>
    </div>
  );
}
export default SavedToggleBtn;
