import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./CurrentEventCard.css";
import mainApi from "../../utils/MainApi";
import YandexShare from "../YandexShare/YandexShare";
import { CurrentSavedEventsContext } from "../../contexts/CurrentSavedEventsContext";
import SaveBtn from "../SaveBtn/SaveBtn";
import Preloader from "../Preloader/Preloader";

function CurrentEventCard({ onClickEventsBtn, loggedIn }) {
  let { event_id } = useParams();
  const [isEventData, setEventData] = useState([]);
  const [isStartTime, setStartTime] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isRender, setRender] = useState(false);
  const CurrentSaved = useContext(CurrentSavedEventsContext);
  const isLiked = CurrentSaved.some(
    (item) => +item.event_id === isEventData.event_id
  );

  useEffect(() => {
    getDataEvent();
    if (isLoading) {
      setRender(true);
      splitDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleClickLike = () => {
    if (!isLiked) {
      onClickEventsBtn(isEventData, "save", isEventData.event_id);
    } else {
      onClickEventsBtn(
        isEventData,
        "delete",
        CurrentSaved.filter(
          (item) => +item.event_id === isEventData.event_id
        )[0].event_id
      );
    }
  };

  function getDataEvent() {
    mainApi
      .getShowEvent(event_id)
      .then((res) => {
        setEventData(res);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function splitDate() {
    const ArrTime = isEventData.start_time.split(/[-,T,:]/);
    setStartTime(ArrTime);
  }

  const getFormatedText = (text) => {
    return text.split("\n").map((str, i) => (
      <p className="current-eventcard__description" key={`p_${i}`}>
        {str}
      </p>
    ));
  };

  return (
    <div className="current-eventcard">
      {isRender ? (
        <>
          <div className="current-eventcard__header-container">
            <img
              className="current-eventcard__poster"
              src={`https://api.clubbingowl.ru/images/${isEventData.images.at(-1)}`}
              alt="Постер мероприятия"
            />
            <div className="current-eventcard__container-age-limit">
              <p className="current-eventcard__text-age-limit">
                {isEventData.age_limit}+
              </p>
            </div>
            <div className="current-eventcard__btns">
              {loggedIn ? (
                <SaveBtn isLiked={isLiked} onClick={handleClickLike} />
              ) : (
                ""
              )}
              <YandexShare />
            </div>
          </div>
          <h1 className="current-eventcard__title">{isEventData.name}</h1>
          <div className="current-eventcard__main-container">
            {getFormatedText(isEventData.description)}
            {isEventData.website !== null ? (
            <a
              href={isEventData.website}
              target="_blank"
              className="current-clubcard__link"
              rel="noreferrer"
            >
              Перейти на сайт
            </a>
          ) : (
            " "
          )}
            <p className="current-eventcard__type">
              {isEventData.type} состоится в{" "}
              <NavLink
                to={"/aboutplace/" + isEventData.place_url}
                className="current-eventcard__place"
              >
                {isEventData.place}
              </NavLink>
            </p>

            <p className="current-eventcard__start-time">
              Начало:{" "}
              {`${isStartTime[2]}.${isStartTime[1]} в ${isStartTime[3]}:${isStartTime[4]}`}
            </p>
          </div>
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
}

export default CurrentEventCard;
