import React from "react";
import Navigation from "../components/Navigation/Navigation";
import EventBar from "../components/EventBar/EventBar";
import Footer from "../components/Footer/Footer";

const EventBarPage = (props) => (
  <React.Fragment>
    <EventBar
      onClickSaveEvent={props.onClickSaveEvent}
      loggedIn={props.loggedIn}
    />
    <Footer handleAboutUsPopupOpen={props.handleAboutUsPopupOpen} />
    <Navigation />
  </React.Fragment>
);

export default EventBarPage;
