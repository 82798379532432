import React, { useState, useContext } from "react";
import "./Saved.css";
import SavedToggleBtn from "../SavedToggleBtn/SavedToggleBtn";
import ClubList from "../ClubList/ClubList";
import EventsList from "../EventsList/EventsList";
import { CurrentSavedPlacesContext } from "../../contexts/CurrentSavedPlacesContext";
import { CurrentSavedEventsContext } from "../../contexts/CurrentSavedEventsContext";

function Saved({ onClickDeletePlace, onClickDeleteEvent }) {
  const [isToggleBtn, setToggleBtn] = useState(true);
  const CurrentSavedPlaces = useContext(CurrentSavedPlacesContext);
  const CurrentSavedEvents = useContext(CurrentSavedEventsContext);
  return (
    <div className="saved">
      <SavedToggleBtn
        isToggleBtn={isToggleBtn}
        setToggleBtn={setToggleBtn}
        textFirstBtn={"Места"}
        textTwoBtn={"События"}
      />
      {isToggleBtn ? (
        <ClubList
          barList={CurrentSavedPlaces}
          movieOptions={"save"}
          onClickPlaceBtn={onClickDeletePlace}
        />
      ) : (
        <EventsList
          arrEvents={CurrentSavedEvents}
          movieOptions={"save"}
          onClickEventsBtn={onClickDeleteEvent}
        />
      )}
    </div>
  );
}
export default Saved;
