import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselCurrentClub.css";

function CarouselCurrentClub(props) {
  return (
    <Carousel
      showArrows={true}
      emulateTouch={true}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      autoPlay={true}
    >
      {props.allImg.map((img) => {
        return (
          <div
            key={img.toString()}
            className="carousel-current-club__container"
          >
            <img
              className="carousel-current-club__img"
              src={`https://api.clubbingowl.ru/images/${img}`}
              alt="Картинка из клуба"
            />
          </div>
        );
      })}
    </Carousel>
  );
}

export default CarouselCurrentClub;
