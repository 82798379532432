import React, { useState } from "react";
import "./SearchForm.css";

function SearchForm({ onSubmit, movieOptions, showAll, placeholderText }) {
  const [searchString, setSearchString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const btnShowAll = document.querySelector(".searchform__showall-btn");

  const handleStringChange = (event) => {
    setSearchString(event.target.value);
    setErrorMessage(event.target.validationMessage);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!searchString) {
      setErrorMessage("Нужно ввести ключевое слово");
      setTimeout(() => {
        setErrorMessage(" ");
      }, 1500);
    } else if (searchString) {
      onSubmit(searchString);
      btnShowAll.classList.add("searchform__showall-btn_active");
      localStorage.removeItem("searchStringText");
      localStorage.removeItem("searchEventsStringText");
    }
  };

  function handleShowAll() {
    btnShowAll.classList.remove("searchform__showall-btn_active");
    setSearchString("");
    setErrorMessage("");
    showAll();
  }

  return (
    <div className="searchform">
      <form
        action=""
        method="post"
        className="searchform__line"
        onSubmit={handleSubmit}
        noValidate
      >
        <input
          type="button"
          name="search-showall-btn"
          value=""
          className="searchform__showall-btn"
          onClick={handleShowAll}
        />
        <input
          type="text"
          name="search-input "
          placeholder={placeholderText}
          className="searchform__input"
          value={searchString}
          onChange={handleStringChange}
        />
        <input
          type="submit"
          name="search-input-btn"
          value=""
          className="searchform__input-btn"
        />
        <p className="searchform__error">{errorMessage}</p>
      </form>
    </div>
  );
}

export default SearchForm;
