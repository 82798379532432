import React from "react";
import "./Developer.css";

function Developer(props) {
  return (
    <div className="developer">
      <img className="developer__avatart" alt="аватар" src={props.avatar} />
      <ul className="developer__info">
        <li>{props.role}</li>
        <li>{props.name}</li>
        <li className="developer__telegram">{props.telegram}</li>
      </ul>
    </div>
  );
}

export default Developer;
