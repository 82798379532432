import React from "react";
import "./Form.css";
import { NavLink } from "react-router-dom";

function Form({
  name,
  title,
  children,
  FormBtnRegister,
  textBtn,
  textSpan,
  Link,
  textLink,
  isDisabled,
  onSubmit,
  isRegisterError,
  isLoginError,
  isErrorText,
  isAdminLogin,
}) {
  return (
    <form className="form" name={name} noValidate onSubmit={onSubmit}>
      <h2
        className={
          FormBtnRegister ? "form__title-register" : "form__title-login"
        }
      >
        {title}
      </h2>
      {children}
      <fieldset className="form__submit">
        {isRegisterError && <span className="form__error">{isErrorText}</span>}
        {isLoginError && <span className="form__error">{isErrorText}</span>}
        <button
          className={
            isDisabled
              ? "form__submit-btn form__submit-btn_inactive"
              : "form__submit-btn"
          }
          type="submit"
          disabled={isDisabled}
        >
          {textBtn}
        </button>
        {isAdminLogin ? (
          <p className="form__link-admin-text">Для регистрации в админ панели напишите нам на почту clubbingowl@yandex.ru</p>
        ) : (
          <nav className="form__links-text">
            <span className="form__links-span">{textSpan}</span>
            <NavLink to={Link} className="form__link-text">
              {textLink}
            </NavLink>
          </nav>
        )}
      </fieldset>
    </form>
  );
}

export default Form;
