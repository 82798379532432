import React from "react";
import "./EventsList.css";
import EventsCard from "../EventsCard/EventsCard";

function EventsList({ arrEvents, movieOptions, onClickEventsBtn, loggedIn }) {
  return (
    <div className="events-list">
      {arrEvents.map((EventCard) => (
        <EventsCard
          key={EventCard.event_id}
          EventCard={EventCard}
          onClickEventsBtn={onClickEventsBtn}
          movieOptions={movieOptions}
          loggedIn={loggedIn}
        />
      ))}
    </div>
  );
}

export default EventsList;
