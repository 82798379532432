import React from "react";
import "./SaveBtn.css";

function SaveBtn({ isLiked, onClick }) {
  return (
    <button
      className={!isLiked ? "save-btn__like" : "save-btn__like_active"}
      onClick={onClick}
    ></button>
  );
}
export default SaveBtn;
