import React, { useEffect } from "react";
import "./YandexLogin.css";

function YandexLogin(handleLoginYandex) {
  useEffect(() => {
    const token = /access_token=([^&]+)/.exec(document.location.hash)[1];
    handleLoginYandex(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="yandex-login"></div>;
}

export default YandexLogin;
