import React, { useEffect, useState } from "react";
import useValidation from "../../hooks/useValidation";
import adminApi from "../../utils/AdminApi";
import "./AddEventCard.css";

function AddEventCard(props) {
  const { values, setValues, handleChange, errors, isValid, setIsValid } =
    useValidation();
  const [listEvents, setListEvents] = useState({});
  const [showList, setShowList] = useState(true);
  const [eventInfo, setEventInfo] = useState({});
  const [renameBtn, setRenameBtn] = useState(false);
  const [eventTypes, setEventTypes] = useState({})
  const placeDrop = document.getElementById("place_id");
  const typeList = document.getElementById("type_id");

  useEffect(() => {
    adminApi.getTypesEvents()
    .then((data) => {
      setEventTypes(data)
    })
    .catch((err) => {
      console.error(err);
    });
  }, [])

  useEffect(() => {
    if (listEvents.length > 0) {
      setShowList(false);
    }
  }, [listEvents]);

  useEffect(() => {
    setValues(eventInfo);
    setIsValid(true);
    if (eventInfo.event_id >= 0) {
      autocompleteInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo, setValues, setIsValid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleAddCardEvent(values);
  };

  const handleSubmitRemane = (e) => {
    e.preventDefault();
    props.handleRenameEvent(values, eventInfo.event_id);
  };

  const handleAvailableEvents = () => {
    adminApi
      .getHandleAvailableEvents()
      .then((data) => {
        if (data.length > 0) {
          setListEvents(data);
        } else {
          alert("Ваш список событий пуст");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function handleInfoEvent() {
    const sel = document.getElementById("managerEventsList").selectedIndex;
    const options = document.getElementById("managerEventsList").options;

    if (options[sel].value >= 0) {
      adminApi
        .getHandleInfoEvent(options[sel].value)
        .then((data) => {
          setEventInfo(data);
          setRenameBtn(true);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setRenameBtn(false);
      resetAutocompleteInput();
    }
  }

  function handleDeleteEvent() {
    adminApi
      .handleDeleteEvent(eventInfo.event_id)
      .then(() => {
        resetAutocompleteInput();
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleRenameImg() {
    props.handleRenameImgEvent(eventInfo)
  }

  function autocompleteInput() {
    values.name = eventInfo.name;
    values.description = eventInfo.description;
    values.artist = eventInfo.artist;
    values.age_limit = eventInfo.age_limit;
    values.start_time = eventInfo.start_time;
    values.end_time = eventInfo.end_time;
    placeDrop.value = eventInfo.place_id;
    typeList.value = eventInfo.type_id;
    values.website = eventInfo.website;
  }

  function resetAutocompleteInput() {
    values.name = "";
    values.description = "";
    values.artist = "";
    values.age_limit = "";
    values.start_time = "";
    values.end_time = "";
    placeDrop.value = "";
    typeList.value = "";
    values.website = "";
  }

  return (
    <div className="add-event-card">
      {showList ? (
        <button
          className="add-event-card__show-list-btn"
          onClick={handleAvailableEvents}
        >
          Список событий
        </button>
      ) : (
        <select
          id="managerEventsList"
          className="profile__input-item profile__input-item_manager-event-list"
          name="manager_events_list"
          onChange={handleInfoEvent}
        >
          <option>Выберите событие из списка</option>
          {listEvents.map((cardevent) => (
            <option key={cardevent.event_id} value={cardevent.event_id}>
              {cardevent.event_name}
            </option>
          ))}
        </select>
      )}
      <form
        className="profile__container"
        id="add_event_form"
        onSubmit={renameBtn ? handleSubmitRemane : handleSubmit}
      >
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Название<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item profile__input-item-add-event"
            id="name"
            name="name"
            placeholder="Название"
            required
            onChange={handleChange}
            value={values.name || ""}
          />
          <span className="profile__input-error">{errors.name}</span>
        </div>
        <div className="profile__input profile__input-description">
          <label className="profile__input-label profile__input-label-add-event">
            Описание<span className="register__required">*</span>
          </label>
          <textarea
            type="text"
            className="profile__input-item profile__input-item-add-event profile__input-item-description"
            id="description"
            name="description"
            placeholder="Описание"
            onChange={handleChange}
            value={values.description || ""}
            required
          />
          <span className="profile__input-error">{errors.description}</span>
        </div>
        <div className="profile__input profile__input-description">
          <label className="profile__input-label profile__input-label-add-event">
            Артисты
          </label>
          <textarea
            type="text"
            className="profile__input-item profile__input-item-add-event profile__input-item-description"
            id="artist"
            name="artist"
            placeholder="Артисты"
            onChange={handleChange}
            value={values.artist || ""}
          />
          <span className="profile__input-error">{errors.artist}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Возрастное ограничение<span className="register__required">*</span>
          </label>
          <input
            type="number"
            className="profile__input-item profile__input-item-add-event"
            id="age_limit"
            name="age_limit"
            placeholder="18"
            onChange={handleChange}
            value={values.age_limit || ""}
            required
          />
          <span className="profile__input-error">{errors.age_limit}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Начало события<span className="register__required">*</span>
          </label>
          <input
            type="datetime-local"
            className="profile__input-item profile__input-item-add-event"
            id="start_time"
            name="start_time"
            placeholder="Начало события"
            onChange={handleChange}
            value={values.start_time || ""}
            required
          />
          <span className="profile__input-error">{errors.start_time}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Окончание события
          </label>
          <input
            type="datetime-local"
            className="profile__input-item profile__input-item-add-event"
            id="end_time"
            name="end_time"
            placeholder="Окончание события"
            onChange={handleChange}
            value={values.end_time || ""}
          />
          <span className="profile__input-error">{errors.end_time}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Место<span className="register__required">*</span>
          </label>
          <select
            id="place_id"
            className="profile__input-item profile__input-item-add-event"
            form="add_event_form"
            onChange={handleChange}
            name="place_id"
            required="required"
          >
            <option value="">Выберите место</option>
            {props.listPlaces.map((place) => (
              <option key={place.place_id} value={place.place_id}>{place.place_name}</option>
            ))}
          </select>
          <span className="profile__input-error">{errors.place_id}</span>
        </div>
        {eventTypes.length > 0 && 
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Тип события<span className="register__required">*</span>
          </label>
          <select
            id="type_id"
            className="profile__input-item profile__input-item-add-event"
            form="add_event_form"
            onChange={handleChange}
            name="type_id"
            required="required"
          >
            <option value="">Выберите тип</option>
            {eventTypes.map((type) => (
            <option key={type.type_id} value={type.type_id}>
            {type.type}
          </option>))}
          </select>
          <span className="profile__input-error">{errors.type_id}</span>
        </div>}
        <div className="profile__input">
          <label className="profile__input-label profile__input-label-add-event">
            Сайт события
          </label>
          <input
            type="url"
            className="profile__input-item profile__input-item-add-event"
            id="website"
            name="website"
            placeholder="Введите ссылку"
            onChange={handleChange}
            value={values.website || ""}
          />
          <span className="profile__input-error">{errors.website}</span>
        </div>
        {renameBtn ? (
          <fieldset className="add-event-card__submit-group">
            <button
              className="add-event-card__delete-rename-btn"
              type="submit"
              id="btn-rename-event"
            >
              Изменить событие
            </button>
            <button
              className="add-event-card__delete-rename-btn"
              type="button"
              id="btn-delete-event"
              onClick={handleDeleteEvent}
            >
              Удалить событие
            </button>
            <button
              className="add-event-card__delete-rename-btn"
              type="button"
              id="btn-no-rename-event"
              onClick={handleRenameImg}
            >
              Изменить картинку
            </button>
          </fieldset>
        ) : (
          <fieldset className="profile__submit-group">
            <button
              className={
                isValid && values !== null
                  ? "profile__button"
                  : "profile__button profile__button-inactive"
              }
              disabled={values === null || !isValid}
              type="submit"
              id="button-add-event"
            >
              Добавить
            </button>
          </fieldset>
        )}
      </form>
    </div>
  );
}
export default AddEventCard;
