import React, { useEffect, useState } from "react";
import "./AddImgForCard.css";
import adminApi from "../../utils/AdminApi";

function AddImgForCard(props) {
  const [successAddPlaceImage, setSuccessAddPlaceImage] = useState(false);
  const [loadImage, setLoadImage] = useState(null);
  const [previewLoadImage, setPreviewLoadImage] = useState(null);

  useEffect(() => {
    const objectUrl = loadImage && URL.createObjectURL(loadImage);
    setPreviewLoadImage(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [loadImage]);

  const handleSubmitImage = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("image", loadImage);
    props.handleUpdateImageForCard(formdata, props.newCardId);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleSubmitPlaceImage = (e) => {
    setLoadImage(e.target.files[0]);
    setSuccessAddPlaceImage(true);
    const formdata = new FormData();
    formdata.append("image", e.target.files[0]);
    props.handleUpdateImageForCard(formdata, props.newCardId);
  };

  function handleClose() {
    window.location.reload();
  }

  function handleDeleteImg(e) {
    const src = e.target.nextSibling.currentSrc;
    const image_url = src.slice(34);

    if (props.allPlaceImg.length > 1) {
      adminApi
      .deleteImageForPlace(image_url)
      .then(() => {
        props.setAllPlaceImg((listImg) => listImg.filter((img) => img !== image_url))
      })
      .catch((err) => {
        console.error(err);
      });
    } else {
      alert("Нельзя удалить единственную картинку")
    }
  }

  return (
    <div className="add-img-card">
      {props.addPlaceImage ? (
        <>
          {props.allPlaceImg.length === 0 ? (
            ""
          ) : (
            <div className="add-img-card__all-img-container">
              <p className="add-img-card__required-text">
                Список всех изображений данного места. Для удаления картинки
                нажмите на крестик расположенный в правом верхнем углу
                изображения.
              </p>
              {props.allPlaceImg.map((img) => (
                <div className="add-img-card__img-box" key={img}>
                  <button
                    className="add-img-card__delete-btn"
                    onClick={handleDeleteImg}
                  ></button>
                  <img
                    className="add-img-card__img"
                    src={`https://api.clubbingowl.ru/images/${img}`}
                    alt=" "
                  />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        ""
      )}

      <form
        className="add-img-card__container"
        id="add_event-image_form"
        onSubmit={handleSubmitImage}
      >
        <div className="profile__avatar-box">
          <img
            className="add-img-card__img"
            src={
              props.renameCardImg
                ? previewLoadImage ||
                  `https://api.clubbingowl.ru/images/${props.renameImg}`
                : previewLoadImage
            }
            alt=" "
          />
          <label
            className="add-img-card__img-label"
            htmlFor="image-events"
          ></label>
          <input
            type="file"
            className="profile__avatar-edit-button"
            id="image-events"
            name="image"
            accept=".png, .jpg, .jpeg"
            value=""
            onChange={
              props.addPlaceImage
                ? handleSubmitPlaceImage
                : (e) => setLoadImage(e.target.files[0])
            }
          ></input>
        </div>

        <fieldset className="profile__submit-group">
          {props.renameCardImg ? (
            <p className="add-img-card__required-text">
              {successAddPlaceImage
                ? `Ваша картинка успешно добавлена, вы можете нажать на изображение
            сверху и добавить еще одну картинку, или нажать кнопку "Закрыть" для
            возврата в админ-панель`
                : `Для добавления новой картинки нажмите на изображение сверху.`}
            </p>
          ) : (
            <p className="add-img-card__required-text">
              {successAddPlaceImage
                ? `Ваша картинка успешно добавлена, вы можете нажать на изображение
            сверху и добавить еще одну картинку, или нажать кнопку "Закрыть" для
            возврата в админ-панель`
                : `Не забудьте добавить изображение, иначе ваша карточка не появится
            в списке. Что бы добавить картинку нажмите на сову сверху`}
            </p>
          )}
          {props.addPlaceImage ? (
            <>
              {successAddPlaceImage ? (
                <button
                  className="profile__button"
                  type="button"
                  onClick={handleClose}
                >
                  Закрыть
                </button>
              ) : (
                <>
                  {props.renameCardImg ? (
                    <button
                      className="profile__button"
                      type="button"
                      onClick={handleClose}
                    >
                      Не изменять
                    </button>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <button
                className={
                  loadImage === null
                    ? "profile__button profile__button-inactive"
                    : "profile__button"
                }
                disabled={loadImage === null}
                type="submit"
                id="image-events__button"
              >
                {props.renameCardImg ? "Изменить" : "Добавить"}
              </button>
              {props.renameCardImg ? (
                <button
                  className="profile__button"
                  type="button"
                  onClick={handleClose}
                >
                  Не изменять
                </button>
              ) : (
                ""
              )}
            </>
          )}
        </fieldset>
      </form>
    </div>
  );
}
export default AddImgForCard;
