import React from "react";
import "./Footer.css";

function Footer(props) {
  return (
    <footer className="footer">
      <h2 className="footer__text">Тестовый проект ClubbingOwl</h2>
      <div className="footer__copyright">
        <p className="footer__copyright-text">&copy; 2022</p>
        <div className="footer__links">
          <button
            className="footer__link"
            onClick={props.handleAboutUsPopupOpen}
          >
            О нас
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
