import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./ConfirmEmail.css";
import * as auth from "../../utils/auth";

function ConfirmEmail() {
  useEffect(() => {
    const url = window.location.pathname;
    Confirm(url);
  }, []);

  function Confirm(url) {
    auth
      .handleConfirm(url)
      .then(() => {
        console.log("Вы успешно подтвердили ваш email");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className="confirm-email">
      <h1 className="confirm-email__text">
        Мы рады видеть вас в наших рядах! Вы успешно подтвердили email
      </h1>
      <NavLink to="/signin" className="confirm-email__link">
        Войти
      </NavLink>
    </div>
  );
}

export default ConfirmEmail;
