export const BASE_URL = "https://api.clubbingowl.ru";

const checkResponse = (res) => {
  return res.ok ? res.json() : Promise.reject(`Ошибка ${res.status}`);
};

export const register = (
  email,
  fullname,
  city,
  phone_number,
  date_of_birth,
  password
) => {
  return fetch(`${BASE_URL}/users/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      fullname,
      country: "Россия",
      city,
      phone_number,
      date_of_birth,
      password,
    }),
  }).then((res) => checkResponse(res));
};

export const authorize = (password, email) => {
  return fetch(`${BASE_URL}/users/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password, email }),
  }).then((res) => {
    if (res.ok) {
      const data = [res.headers.get("x-auth-token"), res.json()];
      return data;
    } else return Promise.reject(`Ошибка ${res.status}`);
  });
};

export const authorizeYandex = (token) => {
  return fetch(`${BASE_URL}/users/yandex/auth?token=${token}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    if (res.ok) {
      const data = [res.headers.get("x-auth-token"), res.json()];
      return data;
    } else return Promise.reject(`Ошибка ${res.status}`);
  });
};

export const handleConfirm = (url) => {
  return fetch(`${BASE_URL}${url}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => this._checkResponse(res));
};

export const authorizeAdmin = (password, email) => {
  return fetch(`${BASE_URL}/users/signin?manager=true`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ password, email }),
  }).then((res) => {
    if (res.ok) {
      const data = [res.headers.get("x-auth-token"), res.json()];
      return data;
    } else return Promise.reject(`Ошибка ${res.status}`);
  });
};

export const checkTokenAdmin = (token) => {
  return fetch(`${BASE_URL}/users/manager_info`, {
    method: "GET",
    headers: {
      "X-Auth-Token": token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => checkResponse(res));
};

export const checkToken = (token, userId) => {
  return fetch(`${BASE_URL}/users/${userId}`, {
    method: "GET",
    headers: {
      "X-Auth-Token": token,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => checkResponse(res));
};
