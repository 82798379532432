import { BASE_URL } from "./auth";

class AdminApi {
  constructor(options) {
    this._baseUrl = options.baseUrl;
    this._headers = options.headers;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  }

  addCardPlace(data) {
    return fetch(`${BASE_URL}/places/add`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: data.name || undefined,
        website: data.website || undefined,
        url_id: data.url_id || undefined,
        country: data.country || undefined,
        city: data.city || undefined,
        address: data.address || undefined,
        phone: data.phone || undefined,
        description: data.description || undefined,
        age_limit: data.age_limit || undefined,
        type_id: data.type_id || undefined,
      }),
    }).then((res) => this._checkResponse(res));
  }

  getHandleAvailablePlaces() {
    return fetch(`${BASE_URL}/users/manager/available_places`, {
      method: "GET",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getHandleInfoPlace(placeId) {
    return fetch(`${BASE_URL}/places/${placeId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getTypesPlaces() {
    return fetch(`${BASE_URL}/places/types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  handleRenamePlaceInfo(data, placeId) {
    return fetch(`${BASE_URL}/places/${placeId}`, {
      method: "PUT",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: data.name,
        website: data.website,
        url_id: data.url_id,
        country: data.country,
        city: data.city,
        address: data.address,
        phone: data.phone,
        description: data.description,
        age_limit: Number(data.age_limit),
        type_id: Number(data.type_id),
      }),
    }).then((res) => this._checkResponse(res));
  }

  handleDeletePlace(placeId) {
    return fetch(`${BASE_URL}/places/${placeId}`, {
      method: "DELETE",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  addImageForPlace(form, placeId) {
    return fetch(`${BASE_URL}/places/image/${placeId}`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
      },
      body: form,
    }).then((res) => this._checkResponse(res));
  }

  deleteImageForPlace(imageUrl) {
    return fetch(`${BASE_URL}/places/image/${imageUrl}`, {
      method: "DELETE",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  addCardEvent(data) {
    return fetch(`${BASE_URL}/events/add`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name: data.name || undefined,
        description: data.description || undefined,
        artist: data.artist || undefined,
        age_limit: data.age_limit || undefined,
        start_time: data.start_time || undefined,
        end_time: data.end_time || undefined,
        place_id: data.place_id || undefined,
        type_id: data.type_id || undefined,
        website: data.website || undefined,
      }),
    }).then((res) => this._checkResponse(res));
  }

  addImageForEvent(form, eventId) {
    return fetch(`${BASE_URL}/events/upload_image/${eventId}`, {
      method: "POST",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
      },
      body: form,
    }).then((res) => this._checkResponse(res));
  }

  getHandleAvailableEvents() {
    return fetch(`${BASE_URL}/users/manager/available_events`, {
      method: "GET",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getHandleInfoEvent(eventId) {
    return fetch(`${BASE_URL}/events/${eventId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getTypesEvents() {
    return fetch(`${BASE_URL}/events/types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  handleRenameEventInfo(data, eventId) {
    return fetch(`${BASE_URL}/events/${eventId}`, {
      method: "PUT",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  handleDeleteEvent(eventId) {
    return fetch(`${BASE_URL}/events/${eventId}`, {
      method: "DELETE",
      headers: {
        "X-Auth-Token": `${localStorage.getItem("adminJwt")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }
}

const adminApi = new AdminApi({
  baseUrl: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default adminApi;
