import React from "react";
import "./Popup.css";
import defAvater from "../../images/logo.png";
import Developer from "../Developer/Developer";

function Popup(props) {
  return (
    <div
      className={`popup ${props.isOpen ? "popup_open" : ""}`}
      onClick={props.onClose}
    >
      <div
        className={`popup__container ${
          props.isOpen ? "popup__container_open" : ""
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <h3 className="popup__title">О нас</h3>
        <p className="popup__text">
          Проект создан для быстрого и комфортного поиска мероприятий или
          заведений.
        </p>
        <ul className="popup__list-developers">
          Над проектом работали:
          <li className="popup__info">
            <Developer
              avatar={defAvater}
              role="CEO, Back-end"
              name="Максим Макаров"
              telegram="@MaximMakarov"
            />
          </li>
          <li className="popup__info">
            <Developer
              avatar={defAvater}
              role="Analyst"
              name="Наталья Человская"
              telegram="@NIChelovskaya"
            />
          </li>
          <li className="popup__info">
            <Developer
              avatar={defAvater}
              role="Data Scientist"
              name="Александра Михеева"
              telegram="@agmikheeva"
            />
          </li>
          <li className="popup__info">
            <Developer
              avatar={defAvater}
              role="Front-end, Design"
              name="Максим Человский"
              telegram="@foxymax"
            />
          </li>
        </ul>
        <button className="popup__btn-close" onClick={props.onClose}>
          закрыть
        </button>
      </div>
    </div>
  );
}

export default Popup;
