import React from "react";
import "./ClubList.css";
import ClubCard from "../ClubCard/ClubCard";

function ClubList({ barList, onClickPlaceBtn, movieOptions, loggedIn }) {
  return (
    <div className="club-list">
      {barList.map((BarCard) => (
        <ClubCard
          key={BarCard.place_id}
          bar={BarCard}
          onClickPlaceBtn={onClickPlaceBtn}
          movieOptions={movieOptions}
          loggedIn={loggedIn}
        />
      ))}
    </div>
  );
}

export default ClubList;
