import React, { Component } from "react";
import "./YandexShare.css";

class YandexShare extends Component {
  componentDidMount() {
    window.Ya.share2("ya-share2", {
      content: {
        title: "Привет!",
      },
      theme: {
        counter: true,
        size: "m",
      },
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        id="ya-share2"
        className="ya-share2"
        data-curtain
        data-limit="0"
        data-shape="round"
        data-color-scheme="blackwhite"
        data-more-button-type="short"
        data-direction="vertical"
        data-popup-direction="bottom"
        data-popup-position="outer"
        data-services="vkontakte,telegram,whatsapp"
        data-url={document.location.href}
      ></div>
    );
  }
}

export default YandexShare;
