import React, { useState, useEffect } from "react";
import "./Main.css";
import SearchForm from "../SearchForm/SearchForm";
import ClubList from "../ClubList/ClubList";
import mainApi from "../../utils/MainApi";
import Preloader from "../Preloader/Preloader";

function Main({ onClickSavePlace, loggedIn }) {
  const [isBar, setBar] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isRender, setRender] = useState(false);

  useEffect(() => {
    const dataBar = JSON.parse(sessionStorage.getItem("dataBar"));
    if (dataBar === null) {
      getDataBar();
      setTimeout(() => {
        sessionStorage.removeItem("dataBar");
      }, 3600000);
    } else {
      setBar(dataBar);
      setLoading(true);
    }
    if (isLoading) {
      setRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function getDataBar() {
    mainApi.getAllPlaces().then((res) => {
      sessionStorage.setItem("dataBar", JSON.stringify(res));
      setBar(res);
      setLoading(true);
    });
  }

  function searchBar() {
    const dataBar = JSON.parse(sessionStorage.getItem("dataBar"));
    const searchStringText = localStorage
      .getItem("searchStringText")
      .toLowerCase();
    const listFoundBar = dataBar.filter(
      (bar) => bar.name.toLowerCase().indexOf(searchStringText) >= 0
    );
    setBar(listFoundBar);
    return listFoundBar;
  }

  function handleSearchBar(searchStringText) {
    localStorage.setItem("searchStringText", searchStringText);
    searchBar();
  }

  function handleClickShowAll() {
    const dataBar = JSON.parse(sessionStorage.getItem("dataBar"));
    setBar(dataBar);
  }

  return (
    <main className="main">
      <SearchForm
        onSubmit={handleSearchBar}
        movieOptions={"all"}
        showAll={handleClickShowAll}
        placeholderText={"Поиск места"}
      />
      {isRender ? (
        <ClubList
          barList={isBar}
          movieOptions={"all"}
          onClickPlaceBtn={onClickSavePlace}
          loggedIn={loggedIn}
        />
      ) : (
        <Preloader />
      )}
    </main>
  );
}

export default Main;
