import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import adminApi from "../../utils/AdminApi";
import * as auth from "../../utils/auth";
import AddClubCard from "../../components/AddClubCard/AddClubCard";
import AddEventCard from "../../components/AddEventCard/AddEventCard";
import SavedToggleBtn from "../../components/SavedToggleBtn/SavedToggleBtn";
import Login from "../../components/Login/Login";
import "./AdminPage.css";
import AddImgForCard from "../../components/AddImgForCard/AddImgForCard";

function AdminPage() {
  const [isToggleBtn, setToggleBtn] = useState(true);
  const [loggedInAdmin, setLoggedInAdmin] = useState(false);
  const [isLoginError, setLoginError] = useState(false);
  const [isErrorText, setErrorText] = useState("");
  const [accessAddImg, setAccessAddImg] = useState(false);
  const [renameCardImg, setRenameCardImg] = useState(false);
  const [renameImg, setRenameImg] = useState({});
  const [eventId, setEventId] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [allPlaceImg, setAllPlaceImg] = useState([]);
  const [listPlaces, setListPlaces] = useState({});
  const [manager, setManager] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (!loggedInAdmin) {
      checkTokenAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginAdmin = (password, email) => {
    auth
      .authorizeAdmin(password, email)
      .then((data) => {
        console.log(data)
        if (data[0]) {
          localStorage.setItem("adminJwt", data[0]);
          getAvailablePlaces();
          (async () => {
            const result = await data[1];
            if (result.superuser === false) {
              setManager(true);
            }
          })();
          setLoggedInAdmin(true);
          navigate("/admin");
        }
      })
      .catch((err) => {
        setLoginError(true);
        if (err === "Ошибка 401")
          setErrorText(
            "Возможно вы не зарегистрированы или ввели неверные данные"
          );
        if (err === "Ошибка 429")
          setErrorText(
            "Превышено максимально количество авторизации, подождите пару минут и попробуйте снова"
          );
        console.error(err);
      });
  };

  function checkTokenAdmin() {
    const token = localStorage.getItem("adminJwt");
    if (token) {
      auth
        .checkTokenAdmin(token)
        .then((data) => {
          if (data) {
            getAvailablePlaces();
            setLoggedInAdmin(true);
            if (data.superuser === false) {
              setManager(true);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const handleLogoutAdmin = () => {
    localStorage.removeItem("adminJwt");
    localStorage.removeItem("adminId");
    setLoggedInAdmin(false);
    setManager(false);
    setErrorText("");
  };

  const handleAddCardPlace = (newPlaceData) => {
    adminApi
      .addCardPlace(newPlaceData)
      .then((res) => {
        setPlaceId(res.place_id);
        if (res.place_id) {
          setTimeout(() => {
            setAllPlaceImg([])
            setAccessAddImg(true);
          }, 500);
        }
      })
      .catch((err) => {
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  function handleRenamePlace(dataPlace, placeId) {
    adminApi
      .handleRenamePlaceInfo(dataPlace, placeId)
      .then(() => {
        console.log("Данные успешно изменены");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getAvailablePlaces() {
    adminApi
      .getHandleAvailablePlaces()
      .then((data) => {
        if (data.length > 0) {
          setListPlaces(data);
        } else {
          alert("У вас нет доступных мест");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleUpdateImageForPlace = (newImg, placeId) => {
    adminApi
      .addImageForPlace(newImg, placeId)
      .then((data) => {
        if (data) {
        setAllPlaceImg((prev) => [...prev, data.filename])
        }
      })
      .catch((err) => {
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  function handleRenameImgPlace(dataPlace) {
    setAccessAddImg(true);
    setRenameCardImg(true);
    setRenameImg(dataPlace.images.at(-1) || " ");
    setPlaceId(dataPlace.place_id);
    setAllPlaceImg(dataPlace.images)
  }

  const handleAddCardEvent = (newEventData) => {
    adminApi
      .addCardEvent(newEventData)
      .then((res) => {
        setEventId(res.event_id);
        if (res.event_id) {
          setTimeout(() => {
            setAccessAddImg(true);
          }, 500);
        }
      })
      .catch((err) => {
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  function handleRenameEvent(dataEvent, eventId) {
    adminApi
      .handleRenameEventInfo(dataEvent, eventId)
      .then(() => {
        console.log("Данные успешно изменены");
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleRenameImgEvent(dataEvent) {
    setAccessAddImg(true);
    setRenameCardImg(true);
    setRenameImg(dataEvent.images.at(-1) || " ");
    setEventId(dataEvent.event_id);
  }

  const handleUpdateImageForEvent = (newImg, eventId) => {
    adminApi
      .addImageForEvent(newImg, eventId)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        if (err === "Ошибка: 400") setErrorText("Переданы некорректные данные");
        if (err === "Ошибка: 409")
          setErrorText("Редактирование данных другого пользователя невозможно");
        console.error(err);
      });
  };

  return (
    <div className="admin-page">
      {loggedInAdmin ? (
        <>
          {accessAddImg ? (
            ""
          ) : (
            <SavedToggleBtn
              isToggleBtn={isToggleBtn}
              setToggleBtn={setToggleBtn}
              textFirstBtn={"Места"}
              textTwoBtn={"События"}
            />
          )}
          {isToggleBtn ? (
            <>
              {accessAddImg ? (
                <AddImgForCard
                  newCardId={placeId}
                  handleUpdateImageForCard={handleUpdateImageForPlace}
                  renameCardImg={renameCardImg}
                  renameImg={renameImg}
                  allPlaceImg={allPlaceImg}
                  setAllPlaceImg={setAllPlaceImg}
                  addPlaceImage={true}
                />
              ) : (
                <AddClubCard
                  handleAddCardPlace={handleAddCardPlace}
                  handleRenamePlace={handleRenamePlace}
                  handleRenameImgPlace={handleRenameImgPlace}
                  manager={manager}
                  listPlaces={listPlaces}
                />
              )}
            </>
          ) : (
            <>
              {accessAddImg ? (
                <AddImgForCard
                  newCardId={eventId}
                  handleUpdateImageForCard={handleUpdateImageForEvent}
                  renameCardImg={renameCardImg}
                  renameImg={renameImg}
                />
              ) : (
                <AddEventCard
                  listPlaces={listPlaces}
                  handleRenameEvent={handleRenameEvent}
                  handleAddCardEvent={handleAddCardEvent}
                  handleRenameImgEvent={handleRenameImgEvent}
                />
              )}
            </>
          )}
          <NavLink
            to="/admin"
            className="profile__link"
            onClick={handleLogoutAdmin}
          >
            Выйти из аккаунта
          </NavLink>
        </>
      ) : (
        <Login
          handleLogin={handleLoginAdmin}
          isAdminLogin={true}
          isLoginError={isLoginError}
          isErrorText={isErrorText}
        />
      )}
    </div>
  );
}
export default AdminPage;
