import React, { useEffect, useState } from "react";
import useValidation from "../../hooks/useValidation";
import "./AddClubCard.css";
import adminApi from "../../utils/AdminApi";

function AddClubCard(props) {
  const { values, setValues, handleChange, errors, isValid, setIsValid } =
    useValidation();
  const [placeInfo, setPlaceInfo] = useState({});
  const [showList, setShowList] = useState(false);
  const [renameBtn, setRenameBtn] = useState(false);
  const [placeTypes, setPlaceTypes] = useState({})
  const typeList = document.getElementById("type_placeId");

  useEffect(() => {
    adminApi.getTypesPlaces()
    .then((data) => {
      setPlaceTypes(data)
    })
    .catch((err) => {
      console.error(err);
    });
  }, [])

  useEffect(() => {
    if (props.listPlaces.length > 0) {
      setShowList(true);
    }
  }, [props.listPlaces]);

  useEffect(() => {
    setValues(placeInfo);
    setIsValid(true);
    if (placeInfo.place_id >= 0) {
      autocompleteInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeInfo, setValues, setIsValid]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleAddCardPlace(values);
  };

  const handleSubmitRemane = (e) => {
    e.preventDefault();
    props.handleRenamePlace(values, placeInfo.place_id);
  };

  function handleInfoPlace() {
    const sel = document.getElementById("managerPlacesList").selectedIndex;
    const options = document.getElementById("managerPlacesList").options;

    if (options[sel].id !== "") {
      adminApi
        .getHandleInfoPlace(options[sel].value)
        .then((data) => {
          setPlaceInfo(data);
          setRenameBtn(true);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setRenameBtn(false);
      resetAutocompleteInput();
    }
  }

  function handleDeletePlace() {
    adminApi
      .handleDeletePlace(placeInfo.place_id)
      .then(() => {
        resetAutocompleteInput();
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleRenameImg() {
    props.handleRenameImgPlace(placeInfo)
  }

  function autocompleteInput() {
    values.name = placeInfo.name;
    values.url_id = placeInfo.url_id;
    values.website = placeInfo.website;
    values.country = placeInfo.country;
    values.city = placeInfo.city;
    values.address = placeInfo.address;
    values.phone = placeInfo.phone;
    values.description = placeInfo.description;
    values.age_limit = placeInfo.age_limit;
    typeList.value = placeInfo.type_id;
  }

  function resetAutocompleteInput() {
    values.name = "";
    values.url_id = "";
    values.website = "";
    values.country = "";
    values.city = "";
    values.address = "";
    values.phone = "";
    values.description = "";
    values.age_limit = "";
    typeList.value = "";
  }

  return (
    <div className="add-place-card">
      {showList ? (<select
        id="managerPlacesList"
        className="profile__input-item profile__input-item_manager-event-list"
        name="manager_Places_list"
        onChange={handleInfoPlace}
      >
        <option>Выберите место из списка</option>
        {props.listPlaces.map((cardplace) => (
          <option key={cardplace.place_id} value={cardplace.url_id} id={cardplace.place_id}>
            {cardplace.place_name}
          </option>
        ))}
      </select>) : ("")}
      <form
        className="profile__container"
        id="add_place_form"
        onSubmit={renameBtn ? handleSubmitRemane : handleSubmit}
      >
        <div className="profile__input">
          <label className="profile__input-label">
            Название<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item"
            id="name"
            name="name"
            placeholder="Название"
            required
            onChange={handleChange}
            value={values.name || ""}
          />
          <span className="profile__input-error">{errors.name}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">
            Url_id<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item"
            id="url_id"
            name="url_id"
            placeholder="url_id"
            required
            onChange={handleChange}
            value={values.url_id || ""}
            disabled={props.manager ? "disabled" : ""}
          />
          <span className="profile__input-error">{errors.name}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Сайт</label>
          <input
            type="html"
            className="profile__input-item"
            id="website"
            name="website"
            placeholder="Сайт"
            onChange={handleChange}
            value={values.website|| ""}
          />
          <span className="profile__input-error">{errors.website}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">
            Страна<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item"
            id="country"
            name="country"
            placeholder="Страна"
            onChange={handleChange}
            value={values.country || ""}
            required
          />
          <span className="profile__input-error">{errors.country}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">
            Город<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item"
            id="city"
            name="city"
            maxLength="30"
            placeholder="Город"
            value={values.city || ""}
            required
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.city}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">
            Адрес<span className="register__required">*</span>
          </label>
          <input
            type="text"
            className="profile__input-item"
            id="address"
            name="address"
            placeholder="Адрес"
            value={values.address || ""}
            required
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.address}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">Номер телефона</label>
          <input
            type="tel"
            className="profile__input-item"
            id="phone"
            name="phone"
            minLength={14}
            maxLength={14}
            placeholder="+7(***)*******"
            value={values.phone || ""}
            onChange={handleChange}
          />
          <span className="profile__input-error">{errors.phone}</span>
        </div>
        <div className="profile__input profile__input-description">
          <label className="profile__input-label">
            Описание<span className="register__required">*</span>
          </label>
          <textarea
            type="text"
            className="profile__input-item profile__input-item-description"
            id="description"
            name="description"
            placeholder="Описание"
            value={values.description || ""}
            onChange={handleChange}
            required
          />
          <span className="profile__input-error">{errors.description}</span>
        </div>
        <div className="profile__input">
          <label className="profile__input-label">
            Возрастное ограничение<span className="register__required">*</span>
          </label>
          <input
            type="number"
            className="profile__input-item"
            id="age_limit"
            name="age_limit"
            placeholder="18"
            value={values.age_limit || ""}
            onChange={handleChange}
            required
          />
          <span className="profile__input-error">{errors.age_limit}</span>
        </div>
        {placeTypes.length > 0 &&
        <div className="profile__input">
          <label className="profile__input-label">
            Тип места<span className="register__required">*</span>
          </label>
          <select
            id="type_placeId"
            className="profile__input-item"
            form="add_event_form"
            onChange={handleChange}
            name="type_id"
            required="required"
          >
            <option value="">Выберите тип места</option>
            {placeTypes.map((type) => (
            <option key={type.type_id} value={type.type_id}>
            {type.type}
          </option>))}
          </select>
          <span className="profile__input-error">{errors.type_id}</span>
        </div>
         }
        
        {renameBtn ? (
          <fieldset className="add-event-card__submit-group">
          <button
            className="add-event-card__delete-rename-btn"
            type="submit"
            id="btn-rename-place"
          >
            Изменить данные
          </button>
          {props.manager ? ("") : <button
            className="add-event-card__delete-rename-btn"
            type="button"
            id="btn-delete-place"
            onClick={handleDeletePlace}
          >
            Удалить место
          </button>}
          <button
            className="add-event-card__delete-rename-btn"
            type="button"
            id="btn-no-rename-place"
            onClick={handleRenameImg}
          >
            Изменить картинку
          </button>
        </fieldset>
        ) : (
          <>
            {props.manager ? (
              <p className="add-place-card__warning-text">
                Вы можете только редактировать доступные для вас места, для
                редактирования выберите место из списка. Что бы добвить новое
                место напишите нам clubbingowl@yandex.ru
              </p>
            ) : (
              <fieldset className="profile__submit-group">
            <button
              className={
                isValid && values !== null
                  ? "profile__button"
                  : "profile__button profile__button-inactive"
              }
              disabled={values === null || !isValid}
              type="submit"
              id="button-add-place"
            >
              Добавить
            </button>
          </fieldset>
            )}
          </>
        )}
      </form>
    </div>
  );
}
export default AddClubCard;
