import React from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  return (
    <nav className="navigation">
      <div className="navigation__links">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "navigation__link_active"
              : "navigation__link navigation__icon-mesto"
          }
        >
          Места
        </NavLink>
        <NavLink
          to="/event-bar"
          className={({ isActive }) =>
            isActive
              ? "navigation__link_active"
              : "navigation__link navigation__icon-events"
          }
        >
          События
        </NavLink>

        <NavLink
          to="/map"
          className={({ isActive }) =>
            isActive
              ? "navigation__link_active "
              : "navigation__link navigation__icon-map"
          }
        >
          Карта
        </NavLink>

        <NavLink
          to="/saved"
          className={({ isActive }) =>
            isActive
              ? "navigation__link_active"
              : "navigation__link navigation__icon-saved"
          }
        >
          Избранные
        </NavLink>

        <NavLink
          to="/profile"
          className={({ isActive }) =>
            isActive
              ? "navigation__link_active"
              : "navigation__link navigation__icon-profile"
          }
        >
          Профиль
        </NavLink>
      </div>
    </nav>
  );
}

export default Navigation;
